<template>
  <footer>
    <div class="container">
      <div class="social">
        <a target="_blank" href="https://instagram.com/fotografjonna"><img src="@/assets/footer/instagram.png"/></a>
        <a target="_blank" href="https://facebook.com/studiopetite.se"><img src="@/assets/footer/facebook.png"/></a>
        <a href="mailto:info@studiopetite.se"><img src="@/assets/footer/mail.png"/></a>
      </div>
      <p>Studio Petite AB, Strandtorget 11, 135 63 Tyresö</p>
      <p>Copyright Studio Petite AB - Fotograf Jonna Erlandsson - 2021</p>
    </div>
  </footer>
</template>

<script>
  export default {
    name: "Footer"
  }
</script>

<style scoped lang="stylus">
  footer
    padding: 20px 0
    background-color: #6a6a6a

    div.container
      display: flex
      flex-direction: column
      justify-content: space-between
      align-items: center

      div.social a + a
        margin-left: 10px

      p
        margin: 10px 0 5px
        color: #fff


</style>