<template>
  <div id="checkout" class="container">
    <div v-if="order_status" id="summary" class="grid">
      <p>Valda bilder <span class="highlight">{{selection.length}}</span></p>
      <p>Bildpaket <span class="highlight">{{packageName()}}</span></p>
      <p>Pris <span class="highlight"><span v-if="order_discount > 0 && calculatePrice(false) !== calculatePrice()" class="rebate">{{formatter.format(calculatePrice(false))}} </span>{{formatter.format(calculatePrice())}}</span></p>
    </div>
    <div id="confirmation" class="grid">
      <p v-if="order_status === 'pending'"><input type="checkbox" v-model="termsAccepted"> Jag godkänner <a href="javascript:void(0)" v-on:click="termsVisible = true">beställningsvillkoren</a></p>
      <button v-if="order_status === 'pending'" :disabled="!termsAccepted || selection.length === 0" v-on:click="termsAccepted && selection.length > 0 ? placeOrder() : null">
        <span v-if="selection.length === 0">Du måste välja minst en bild</span>
        <span v-else-if="!termsAccepted">Du måste godkänna beställningsvillkoren</span>
        <span v-else>Beställ</span>
      </button>
      <p v-if="order_status === 'ordering'">Skapar din beställning...</p>
      <p v-if="order_status !== 'pending' && order_status !== 'ordering'">Orderstatus
        <span v-if="order_status === 'finalized'" class="highlight">Levererad</span>
        <span v-else-if="order_status === 'paid'" class="highlight">Under behandling</span>
        <span v-else class="highlight">Inväntar betalning</span>
      </p>
      <p v-if="order_status === 'paid'">Levereras senast <span class="highlight">{{order_latest_delivery_date}}</span></p>
      <p v-if="(order_status === 'paid' || order_status === 'finalized') && calculatePrice() > 0">Betalning <span class="highlight">Registrerad</span><span id="checkmark"></span></p>
    </div>
    <div v-if="order_status === 'ordered' || order_status === 'paying'" id="payment" class="grid">
      <p>Betala med Swish</p>
      <input type="text" placeholder="Ange ditt telefonnummer" v-model="swishPhone"/>
      <button :disabled="!swishPhone || order_status === 'paying'" v-on:click="swishPhone && order_status !== 'paying' ? paySwish() : null">
        <span v-if="order_status === 'ordered' && !swishPhone">Fyll i ditt nummer</span>
        <span v-else-if="order_status === 'ordered'">Skicka förfrågan</span>
        <span v-else>Godkänn betalningen i Swish-appen</span>
      </button>
    </div>
    <span class="error-message">{{errorMessage}}</span>
    <div v-if="termsVisible" id="terms-container">
      <div v-on:click="termsVisible = false" class="close-button"></div>
      <OrderTerms/>
    </div>
  </div>
</template>

<script>
  import OrderTerms from "@/components/OrderTerms";

  import axios from "axios";

  let termsVisible, termsAccepted = undefined;

  let swishPhone = undefined;

  let errorMessage = undefined;

  export default {
    name: "OrderCheckout",
    components: {
      OrderTerms
    },
    props: [
      "selection",
      "order_id",
      "order_status",
      "set_order_status",
      "order_latest_delivery_date",
      "order_minimum_value",
      "order_discount",
      "order_included",
      "order_custom_prices",
      "order_digital_prices",
      "order_images_in_selection",
      "set_order_latest_delivery_date",
      "set_order_receipt_path",
    ],
    data() { return {
      formatter: new Intl.NumberFormat('se-SE', {style: 'currency', currency: 'SEK'}),
      termsVisible: termsVisible,
      termsAccepted: termsAccepted,
      swishPhone: swishPhone,
      errorMessage: errorMessage,
    }},
    methods: {
      calculatePrice(applyDiscount = true) {
        let quantity = this.selection.length - (this.order_included.digital || 0);

        if (!quantity)
          return this.order_minimum_value;

        let upperPackagePrice, unitPrice;
        for (let pkg of this.order_digital_prices) {
          unitPrice = pkg.price / pkg.quantity;
          if (quantity >= pkg.quantity)
            break;
          upperPackagePrice = pkg.price;
        }

        if (unitPrice * quantity > upperPackagePrice)
          unitPrice = upperPackagePrice / quantity;

        return Math.max(quantity * unitPrice - (applyDiscount ? this.order_discount : 0), this.order_minimum_value);
      },
      packageName() {
        let count = this.selection.length;

        let name = '';
        if (this.order_included.digital > 0) {
          name = 'Inkluderat (' + Math.min(count, this.order_included.digital) + ' av ' + this.order_included.digital + ' st)';
          count -= this.order_included.digital;
        }

        if (count > 0 || !this.order_included.digital) {
          if (count < 9) {
            if (count < 5 && this.order_minimum_value < 2500)
              return name + (name ? ' + ' : '') + count + ' st bild' + (count > 1 ? 'er' : '');
            else
              return name + (name ? ' + ' : '') + 'Mellan (' + Math.min(count, 5) + ' av 5 st bilder)' + ((count - 5) > 0 ? ' + ' + (count - 5) + ' st bild' + ((count - 5) > 1 ? 'er' : '') : '');
          }

          if (count < 22)
            return name + (name ? ' + ' : '') + 'Stor (' + Math.min(count, 10) + ' av 10 st bilder)' + ((count - 10) > 0 ? ' + ' + (count - 10) + ' st bild' + ((count - 10) > 1 ? 'er' : '') : '');

          return name + (name ? ' + ' : '') + 'Premium (' + (this.order_images_in_selection <= 40 ? 'Alla' : '40 st') + ' bilder ingår)' + ((count - 40) > 0 ? ' + ' + (count - 40) + ' st bild' + ((count - 40) > 1 ? 'er' : '') : '');
        }

        return name;
      },
      placeOrder() {
        this.set_order_status('ordering');

        let formData = new FormData();
        formData.append('accept_tos', 1);
        formData.append('price', this.calculatePrice());
        this.selection.map(function(obj) {
          formData.append('digital[' + obj.id + ']', obj.comment);
        });

        axios
            .post(this.apiUrl + 'order/place/' + this.order_id, formData)
            .then(function(response) {
              if (!response.data || !response.data.success) {
                this.errorMessage = response.data.data.error || 'Ett okänt fel inträffade, vänligen testa igen.';
                this.set_order_status('pending');
                return;
              }

              this.errorMessage = '';

              this.set_order_status(this.calculatePrice() > 0 ? 'ordered' : 'paid');
            }.bind(this));
      },
      paySwish() {
        let formData = new FormData();
        formData.append('phone', this.swishPhone);

        axios
            .post(this.apiUrl + 'order/swish/' + this.order_id, formData)
            .then(function(response) {
              if (!response.data || !response.data.success) {
                this.errorMessage = response.data.data.error || 'Ett okänt fel inträffade, vänligen testa igen.';
                return;
              }

              this.errorMessage = '';

              setTimeout(this.checkSwish, 3000);
              this.set_order_status('paying');
            }.bind(this));
      },
      checkSwish() {
        axios
          .get(this.apiUrl + 'order/swish_status/' + this.order_id)
          .then(function(response) {
            if (!response.data || !response.data.success) {
              this.errorMessage = response.data.data.error || 'Ett okänt fel inträffade, vänligen testa igen.';
              return;
            }

            this.errorMessage = '';

            if (!response.data.data.paid) {
              setTimeout(this.checkSwish, 3000);
              return;
            }

            this.set_order_status('paid');
            this.set_order_latest_delivery_date(response.data.data.latest_delivery_date);
            this.set_order_receipt_path(response.data.data.receipt_path);
          }.bind(this));

      }
    }
  }
</script>

<style scoped lang="stylus">
  @font-face
    font-family: 'squarespace-ui-font'
    src: url('//assets.squarespace.com/universal/fonts/squarespace-ui-font.eot')
    src: url('//assets.squarespace.com/universal/fonts/squarespace-ui-font.eot?#iefix') format('embedded-opentype'), url('//assets.squarespace.com/universal/fonts/squarespace-ui-font.svg#squarespace-ui-font') format('svg'), url('//assets.squarespace.com/universal/fonts/squarespace-ui-font.woff') format('woff'), url('//assets.squarespace.com/universal/fonts/squarespace-ui-font.ttf') format('truetype')
    font-weight: normal
    font-style: normal

  div#checkout

    div.grid
      display: grid
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
      background-color: #f5f5f5

      & + div.grid
        margin-top: 30px

      input[type=text]
        text-align: center
        border: 1px solid #ccc
        box-sizing: border-box
        outline: none
        height: 48px

        &:focus
          border-color: #aaa

        @media (max-width: 600px)
          border-bottom-width: 0
        @media (min-width: 600px)
          border-right-width: 0

      a
        text-decoration: underline

      button
        border: 1px solid #ccc
        background-color: #eee
        outline: none
        height: 48px
        box-sizing: border-box

        &:hover:not(:disabled)
          background-color: #e5e5e5

      span.highlight
        margin-left: 5px
        font-weight: bold

        span.rebate
          font-size: 12px;
          text-decoration: line-through
          color: #666
          padding-right: 5px

      span#checkmark
        position: absolute
        width: 32px
        height: 32px

        &:before
          font-family: squarespace-ui-font
          font-size: 25px
          color: #81dc76
          content: '\e019'

  div#terms-container
    position: fixed
    left: 0
    top: 0
    right: 0
    bottom: 0
    background-color: rgba(0, 0, 0, 0.5)
    z-index: 999

    div#tos
      max-width: 80vw
      max-height: 90vh
      margin-top: 4vh
      background-color: #fff
      overflow-y: auto

    div.close-button
      position: fixed
      top: 5px
      right:5px
      width: 32px
      height: 32px
      z-index: 130

      &:before
        font-family: squarespace-ui-font
        font-size: 48px
        color: #fff
        line-height: 32px
        margin-left: -8px
        content: '\e02e'
        cursor: pointer

      &:hover:before
        color: #ddd

  span.error-message
    display: table
    margin: 30px auto 0
    font-size: 14px
    color: #c00

</style>