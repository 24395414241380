<template>
  <div id="checkout" class="container">
    <div id="summary" class="grid">
      <p>Fotografering <span class="highlight">{{booking_type}}</span></p>
      <p>Tid & datum <span class="highlight">{{booking_date}}</span></p>
      <p>Fotograferingpris <span class="highlight">{{formatter.format(booking_price)}}</span></p>
    </div>
    <div id="confirmation" class="grid">
      <p v-if="booking_status === 'pending'"><input type="checkbox" v-model="termsAccepted"> Jag godkänner <a href="javascript:void(0)" v-on:click="termsVisible = true">bokningsvillkoren</a></p>
      <button v-if="booking_status === 'pending'" :disabled="!termsAccepted" v-on:click="termsAccepted ? confirmBooking() : null">
        <span v-if="!termsAccepted">Du måste godkänna bokningsvillkoren</span>
        <span v-else>Godkänn</span>
      </button>
      <p v-if="booking_status === 'confirming'">Godkänner villkoren...</p>
      <p v-if="booking_status !== 'pending' && booking_status !== 'confirming'">Bokningsstatus
        <span v-if="booking_status === 'confirmed' && booking_type === 'Nyfödd'" class="highlight">Bekräftad, obetald</span>
        <span v-if="booking_status === 'confirmed' && booking_type !== 'Nyfödd'" class="highlight">Obetald</span>
        <span v-if="booking_status === 'paid'" class="highlight">Bekräftad</span>
      </p>
      <p v-if="booking_status === 'paid' && booking_price > 0">Betalning <span class="highlight">Registrerad</span><span id="checkmark"></span></p>
    </div>
    <div v-if="booking_status === 'confirmed' || booking_status === 'paying'" id="payment" class="grid">
      <p>Betala med Swish</p>
      <input type="text" placeholder="Ange ditt telefonnummer" v-model="swishPhone"/>
      <button :disabled="!swishPhone || booking_status === 'paying'" v-on:click="swishPhone && booking_status !== 'paying' ? paySwish() : null">
        <span v-if="booking_status === 'confirmed' && !swishPhone">Fyll i ditt nummer</span>
        <span v-else-if="booking_status === 'confirmed'">Skicka förfrågan</span>
        <span v-else>Godkänn betalningen i Swish-appen</span>
      </button>
    </div>
    <span class="error-message">{{errorMessage}}</span>
    <div v-if="termsVisible" id="terms-container">
      <div v-on:click="termsVisible = false" class="close-button"></div>
      <BookingTerms/>
    </div>
  </div>
</template>

<script>
  import BookingTerms from "@/components/BookingTerms";

  import axios from "axios";

  let termsVisible, termsAccepted = undefined;

  let swishPhone = undefined;

  let errorMessage = undefined;

  export default {
    name: "BookingCheckout",
    components: {
      BookingTerms
    },
    props: [
      "booking_id",
      "booking_price",
      "booking_type",
      "booking_status",
      "booking_date",
      "set_booking_status",
      "set_booking_receipt_path",
    ],
    data() { return {
      formatter: new Intl.NumberFormat('se-SE', {style: 'currency', currency: 'SEK'}),
      termsVisible: termsVisible,
      termsAccepted: termsAccepted,
      swishPhone: swishPhone,
      errorMessage: errorMessage,
    }},
    methods: {
      confirmBooking() {
        this.set_booking_status('confirming');

        let formData = new FormData();
        formData.append('accept_tos', 1);

        axios
            .post(this.apiUrl + 'booking/confirm/' + this.booking_id, formData)
            .then(function(response) {
              if (!response.data || !response.data.success) {
                this.errorMessage = response.data.data.error || 'Ett okänt fel inträffade, vänligen testa igen.';
                this.set_booking_status('pending');
                return;
              }

              this.errorMessage = '';

              this.set_booking_status(this.booking_price > 0 ? 'confirmed' : 'paid');
            }.bind(this));
      },
      paySwish() {
        let formData = new FormData();
        formData.append('phone', this.swishPhone);

        axios
            .post(this.apiUrl + 'booking/swish/' + this.booking_id, formData)
            .then(function(response) {
              if (!response.data || !response.data.success) {
                this.errorMessage = response.data.data.error || 'Ett okänt fel inträffade, vänligen testa igen.';
                return;
              }

              this.errorMessage = '';

              setTimeout(this.checkSwish, 3000);
              this.set_booking_status('paying');
            }.bind(this));
      },
      checkSwish() {
        axios
          .get(this.apiUrl + 'booking/swish_status/' + this.booking_id)
          .then(function(response) {
            if (!response.data || !response.data.success) {
              this.errorMessage = response.data.data.error || 'Ett okänt fel inträffade, vänligen testa igen.';
              return;
            }

            this.errorMessage = '';

            if (!response.data.data.paid) {
              setTimeout(this.checkSwish, 3000);
              return;
            }

            this.set_booking_status('paid');
            this.set_booking_receipt_path(response.data.data.receipt_path);
          }.bind(this));

      }
    }
  }
</script>

<style scoped lang="stylus">
  @font-face
    font-family: 'squarespace-ui-font'
    src: url('//assets.squarespace.com/universal/fonts/squarespace-ui-font.eot')
    src: url('//assets.squarespace.com/universal/fonts/squarespace-ui-font.eot?#iefix') format('embedded-opentype'), url('//assets.squarespace.com/universal/fonts/squarespace-ui-font.svg#squarespace-ui-font') format('svg'), url('//assets.squarespace.com/universal/fonts/squarespace-ui-font.woff') format('woff'), url('//assets.squarespace.com/universal/fonts/squarespace-ui-font.ttf') format('truetype')
    font-weight: normal
    font-style: normal

  div#checkout

    div.grid
      display: grid
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
      background-color: #f5f5f5

      & + div.grid
        margin-top: 30px

      input[type=checkbox]
        margin: 0
        vertical-align: -1px

      input[type=text]
        text-align: center
        border: 1px solid #ccc
        box-sizing: border-box
        outline: none
        height: 48px

        &:focus
          border-color: #aaa

        @media (max-width: 600px)
          border-bottom-width: 0
        @media (min-width: 600px)
          border-right-width: 0

      a
        text-decoration: underline

      button
        border: 1px solid #ccc
        box-sizing: border-box
        background-color: #eee
        outline: none
        height: 48px

        &:hover:not(:disabled)
          background-color: #e5e5e5

      span.highlight
        margin-left: 5px
        font-weight: bold

      span#checkmark
        position: absolute
        width: 32px
        height: 32px

        &:before
          font-family: squarespace-ui-font
          font-size: 25px
          color: #81dc76
          content: '\e019'

  div#terms-container
    position: fixed
    left: 0
    top: 0
    right: 0
    bottom: 0
    background-color: rgba(0, 0, 0, 0.5)
    z-index: 999

    div#tos
      max-width: 80vw
      max-height: 90vh
      margin-top: 4vh
      background-color: #fff
      overflow-y: auto

    div.close-button
      position: fixed
      top: 5px
      right:5px
      width: 32px
      height: 32px
      z-index: 130

      &:before
        font-family: squarespace-ui-font
        font-size: 48px
        color: #fff
        line-height: 32px
        margin-left: -8px
        content: '\e02e'
        cursor: pointer

      &:hover:before
        color: #ddd

  span.error-message
    display: table
    margin: 30px auto 0
    font-size: 14px
    color: #c00

</style>