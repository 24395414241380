<template>
  <div id="tos">
    <h1 style="padding-top:10px;">Bokningsvillkor</h1>
    <h2>1. Allmänt</h2>
    <div>
      <h3>1.1.</h3>
      <p>
        För att boka en fotografering krävs det att du är 18 år fyllda. Är du minderårig krävs tillstånd från målsman.
      </p>
    </div>
    <div>
      <h3>1.2.</h3>
      <p>
        Fotograferingspriset avser kostnaden för arbetet med själva fotograferingen och är ej återbetalningsbar.
      </p>
    </div>
    <div>
      <h3>1.3.</h3>
      <p>
        Jag reserverar mig för ändringar av priser och prisfel i systemet och kan då justera priset för fotograferingen eller ge kunden möjligheten att kostnadsfritt häva boknigen.
      </p>
    </div>
    <div>
      <h3>1.4.</h3>
      <p>
        Jag förbehåller mig rätten att häva en bokning om bedrägeri misstänks. Försök till bedrägeri polisanmäls.
      </p>
    </div>
    <div>
      <h3>1.5.</h3>
      <p>
        Alla priser på hemsidan är inklusive moms på 25%.
      </p>
    </div>
    <h2>2. Ändring / Avbokning</h2>
    <div>
      <h3>2.1.</h3>
      <p>
        Avbokning måste ske senast 7 dagar innan fotograferingen, annars debiteras hela fotograferingskostnaden. Detta gäller även om du uteblir utan anmälan.
      </p>
    </div>
    <div>
      <h3>2.2.</h3>
      <p>
        Ombokning vid sjukdom med kort varsel är kostnadsfritt. Fotograferingen bokas då om till annat lämpligt datum.
      </p>
    </div>
    <h2>3. Urval</h2>
    <div>
      <h3>3.1.</h3>
      <p>
        Det tar normalt mellan 1-2 veckor från fotograferingen till ett urval visas där du kan beställa bilder från fotograferingen.
      </p>
    </div>
    <div>
      <h3>3.2.</h3>
      <p>
        I samband med högtider eller sjukdom förbehåller jag mig rätten att förlänga tiden tills urvalet finns tillgängligt.
      </p>
    </div>
    <div>
      <h3>3.3.</h3>
      <p>
        Bilderna som fås i urvalet efter fotograferingen är ofärdiga och ska ses som en förhandsvisning av slutprodukten.
      </p>
    </div>
    <div>
      <h3>3.4.</h3>
      <p>
        Bilderna som fås i urvalet är EJ tillåtna att sparas eller användas av kunden.
      </p>
    </div>
    <div>
      <h3>3.5.</h3>
      <p>
        Skulle du mot förmodan vara missnöjd med bilderna så har du rätt till en ny kostnadsfri fotografering om jag anser att det förekommer brister i kvaliteten.
      </p>
    </div>
    <div>
      <h3>3.6.</h3>
      <p>
        Bilderna från urvalet finns sparade i 6 månader, efter den tiden finns ingen skyldighet att tillhandahålla möjligheten att beställa nya bilder.
      </p>
    </div>
    <h2>4. Betalning</h2>
    <div>
      <h3>4.1.</h3>
      <p>
        Fotograferingspriset betalas antingen i förskott eller i senast i samband med fotograferingen.
      </p>
    </div>
    <div>
      <h3>4.2.</h3>
      <p>
        Som konsument betalar du priset för fotograferingen via Swish. Inga extra avgifter tillkommer.
      </p>
    </div>
    <div>
      <h3>4.3.</h3>
      <p>
        Betalningen initieras via denna bokningssida och slutförs genom Swish-appen.
      </p>
    </div>
    <h2>6. Force Majeure</h2>
    <div>
      <h3>6.1.</h3>
      <p>
        Händelse såsom krig, naturkatastrof, stridsåtgärder på arbetsmarknaden, myndighets beslut, uteblivna leveranser från underleverantörer, fördyrande omständigheter samt liknande händelser utanför min kontroll vilka ej skäligen kunnat förutses befriar mig från mina förpliktelser att fullgöra ingångna avtal.
      </p>
    </div>
    <h2>7. Hantering av personuppgifter</h2>
    <div>
      <h3>7.1.</h3>
      <p>
        Jag följer GDPR och sparar endast nödvändinga personuppgifter. Inga personuppgifter delas med tredje part.
      </p>
    </div>
    <div>
      <h3>7.2.</h3>
      <p>
        Bilder från urvalet sparas i 6 månader
      </p>
    </div>
    <div>
      <h3>7.3.</h3>
      <p>
        Porträttbilder anses vara verk och omfattas därför inte av GDPR.
      </p>
    </div>
    <h2>8. Övrigt</h2>
    <div>
      <h3>8.1.</h3>
      <p>
        Vid eventuell tvist följer jag Allmänna Reklamationsnämndens rekommendationer.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BookingTerms"
}
</script>

<style scoped lang="stylus">
  div#tos
    margin: 0 auto
    padding: 20px

    h1
      margin-top: 10px
      font-size: 24px

    h2
      margin-left: 5px
      margin-top: 30px
      font-size: 20px

    div
      display: flex
      justify-content: flex-start
      margin: 7px 0 7px 15px

      h3
        width: 35px
        margin: 0
        font-weight: normal
        letter-spacing: 1px
        line-height: 18px
        font-size: 14px
        text-align: left
        flex-shrink: 0

      p
        line-height: 18px
        font-size: 12px
        text-align: left
        margin: 0

</style>