<template>
  <div class="gallery">
    <div v-if="editable()" id="select-all" v-on:click="selectAll"><button class="selector" :class="{selected: allSelected()}"></button> Välj alla</div>
    <div v-if="openId !== undefined" class="close-button" v-on:click="closeImage()"></div>
    <div class="image-container">
      <div
        class="image"
        v-for="image in visibleImages()"
        :key="image.id"
        :class="{open: openId === image.id, selected: selectedIndex(image.id) !== -1}"
        v-touch:swipe.right="openPrevImage"
        v-touch:swipe.left="openNextImage"
      >
        <img :src="apiUrl + image.path" v-on:click="openId == undefined ? openImage(image.id) : closeImage()">
        <div class="selection">
          <button class="selector" :class="{disabled: !editable()}" v-on:click="editable() ? selectImage(image.id) : null"></button>
          <textarea maxlength="256" :readonly="!editable()" class="comment" :class="{hidden: !selectedImage(image.id).comment && !editable()}" placeholder="Eventuella önskemål om redigering" v-model="selectedImage(image.id).comment"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from "vue"
  import Vue2TouchEvents from "vue2-touch-events"
  Vue.use(Vue2TouchEvents)

  let openId = undefined;

  export default {
    name: "Gallery",
    props: [
      "images",
      "selection",
      "order_status",
    ],
    data() { return {
      openId: openId,
    }},
    methods: {
      editable() {
        return this.order_status === 'pending';
      },
      visibleImages() {
        if (this.editable())
          return this.images;

        return this.images.filter(function(image) {
          return this.selection.find(function(selectedImage) {
            return selectedImage.id === image.id;
          }) !== undefined;
        }.bind(this));
      },
      selectedIndex(id) {
        return this.selection.findIndex(function(obj) {return obj.id == id;});
      },
      selectedImage(id) {
        let imageIndex = this.selectedIndex(id);
        return imageIndex !== -1 ? this.selection[imageIndex] : {};
      },

      selectImage(id) {
        let i = this.selectedIndex(id);
        if (i !== -1)
          this.selection.splice(i, 1);
        else
          this.selection.push({
            id: id,
            comment: ''
          });

        this.$forceUpdate();
      },
      allSelected() {
        return this.visibleImages().length === this.selection.length;
      },
      selectAll() {
        let images = this.visibleImages();
        let allSelected = this.allSelected();
        for (let index in images) {
          let selected = this.selectedIndex(images[index].id) === -1;
          if ((allSelected && !selected) || (!allSelected && selected))
            this.selectImage(images[index].id);
        }
      },
      openImage(id) {
        this.openId = id;
      },
      openPrevImage() {
        let index = this.visibleImages().findIndex(function(image) {
          return image.id === this.openId;
        }.bind(this));

        if (index > 0)
          this.openId = this.visibleImages()[index - 1].id;
      },
      openNextImage() {
        let index = this.visibleImages().findIndex(function(image) {
          return image.id === this.openId;
        }.bind(this));

        if (index > -1 && index < this.visibleImages().length-1)
          this.openId = this.visibleImages()[index + 1].id;
      },
      closeImage() {
        this.openId = undefined;
      }
    },
    mounted() {
      window.addEventListener('keydown', function(e) {
        if (e.code === "ArrowLeft")
          this.openPrevImage();
        else if (e.code === "ArrowRight")
          this.openNextImage();
        else if(e.code === "Escape")
          this.closeImage();
      }.bind(this));
    }
  }
</script>

<style scoped lang="stylus">
  @font-face
    font-family: 'squarespace-ui-font'
    src: url('//assets.squarespace.com/universal/fonts/squarespace-ui-font.eot')
    src: url('//assets.squarespace.com/universal/fonts/squarespace-ui-font.eot?#iefix') format('embedded-opentype'), url('//assets.squarespace.com/universal/fonts/squarespace-ui-font.svg#squarespace-ui-font') format('svg'), url('//assets.squarespace.com/universal/fonts/squarespace-ui-font.woff') format('woff'), url('//assets.squarespace.com/universal/fonts/squarespace-ui-font.ttf') format('truetype')
    font-weight: normal
    font-style: normal

  div.gallery

    div.image-container
      display: grid

      @media (min-width: 600px)
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr))
        gap: 20px
      @media (max-width: 600px)
        grid-template-columns: repeat(2, 0.5fr)
        gap: 10px

    div.image
      display: flex
      flex-direction: column
      justify-content: center

      &.open
        position: fixed
        top: 0
        left: 0
        right: 0
        bottom: 0
        background-color: #fff
        z-index: 110

        @media (min-width: 600px)
          padding: 30px 50px


      img
        display: block
        max-width: 100%
        max-height: 100%
        object-fit: contain
        cursor: zoom-in

      div.selection
        display: flex
        justify-content: center
        align-items: center
        padding: 5px
        background-color: #f5f5f5

        textarea.comment
          visibility: hidden
          width: 0
          padding: 0
          margin: 0
          border: 0
          outline: none
          transition: all 0.3s ease
          overflow: hidden

          @media (max-width: 600px)
            font-size: 10px

          &:focus
            border-color: #aaa

      &.selected

        div.selection

          button.selector:before
            content: '\e012'
            color: #d90909

          textarea.comment:not(.hidden)
            visibility: visible
            overflow: visible
            padding: 5px
            margin-left: 5px
            border: 1px solid #e5e5e5
            width: 100%

      &.open

        img
          cursor: zoom-out


    button.selector
      width: 32px
      height: 32px
      background: transparent
      border: 0
      outline: 0
      padding: 0
      margin: 0 4px 0 5px
      cursor: pointer

      &.disabled
        cursor: initial

      &:before
        font-family: squarespace-ui-font
        font-size: 32px
        line-height: 32px
        color: #bbb
        content: '\e013'

      &.selected:before
        content: '\e012'
        color: #d90909



    div.close-button
      position: fixed
      top: 10px
      right: 10px
      width: 32px
      height: 32px
      z-index: 130

      &:before
        font-family: squarespace-ui-font
        font-size: 48px
        color: #777
        line-height: 32px
        margin-left: -8px
        content: '\e02e'
        cursor: pointer

      &:hover:before
        color: #444

    div#select-all
      display: table
      padding: 5px 15px 5px 5px
      margin-bottom: 10px
      font-size: 14px
      background-color: #f5f5f5

      button
        vertical-align: middle

</style>