<template>
  <div id="booking-page">
      <div id="information-container">
      <div v-if="bookingNotFound" id="booking-not-found">
        <p>Felaktig bokningslänk, vänligen kontakta mig för att få en ny länk.</p>
      </div>
      <div v-if="bookingStatus === 'paid'" class="container">
        <h2>Din fotografering</h2>
        <p v-if="!bookingPassed">Din fotografering är bekräftad{{bookingPrice > 0 ? ' och färdigbetald' : ''}}. Jag ser fram emot att få fotografera er!</p>
        <p v-if="!bookingPassed">Har du frågor eller önskar göra några ändringar är det bara att höra av dig.</p>
        <p v-else>Fotograferingen är nu färdigbetald. Hoppas ni är nöjda med allt!</p>
      </div>
      <div v-if="bookingStatus === 'confirmed' || bookingStatus === 'paying'" class="container">
        <h2>Din fotografering</h2>
        <p v-if="!bookingPassed && bookingType === 'Nyfödd'">Du har nu godkänt villkoren. Återvänd till denna sida efter att du fött för att slutföra betalningen.</p>
        <p v-else-if="!bookingPassed">Du har godkänt villkoren men behöver fortfarande registrera din betalning nedan inom
          72 timmar från det att du mottog länken för att bokningen ska bekräftas.</p>
        <p v-if="!bookingPassed">Har du frågor eller önskar göra några ändringar är det bara att höra av dig.</p>
        <p v-else>Hoppas ni är nöjda med allt!</p>
        <h4>Så här betalar du med Swish</h4>
        <ol>
          <li>Fyll i det nummer du vill betala med och tryck på Skicka förfrågan-knappen</li>
          <li>Öppna Swish-appen och bekräfta betalningen</li>
          <li>Fotograferingen är nu färdigbetald. Du kan ladda ner kvittot längst ner på sidan</li>
        </ol>
      </div>
      <div v-if="bookingStatus === 'pending' || bookingStatus === 'confirming'" class="container">
        <h2>Din fotografering</h2>
        <p>Vad roligt att du valt att boka en fotografering hos mig!</p>
        <p v-if="bookingPrice > 0">På denna sida kompletterar du bokningen med nödvändiga uppgifter, godkänner villkoren och betalar för fotograferingen. Du behöver göra detta
          senast 72 timmar efter att du mottagit länken.</p>
        <p v-else>På denna sida kompletterar du bokningen med nödvändiga uppgifter och godkänner villkoren.</p>
        <p v-if="bookingPrice == 0">Fotograferingen är kostnadsfri, men du kan betalningsskyldig vid sen avbokning.</p>
        <h4>Så här bekräftar du bokningen</h4>
        <ol>
          <li>Komplettera de uppgifter som saknas nedan och klicka på "Spara"</li>
          <li>Kontrollera att informationen om bokningen som datum, tid och pris är korrekta</li>
          <li>Läs igenom bokningsvillkoren. Observera att en avbokningsavgift kan tas vid sen avbokning. Godkänn därefter dessa</li>
          <li v-if="bookingPrice > 0 && bookingType === 'Nyfödd'">Du betalar för fotograferingen på denna sida efter att du har fött. Betalning sker via Swish</li>
          <li v-if="bookingPrice > 0 && bookingType !== 'Nyfödd'">Du betalar för fotograferingen på denna sida. Betalning sker via Swish</li>
          <li v-if="bookingPrice > 0">För att betala; fyll i det nummer du vill betala med och tryck på Skicka förfrågan-knappen</li>
          <li v-if="bookingPrice > 0">Öppna Swish-appen och bekräfta betalningen</li>
        </ol>
      </div>
    </div>
    <div v-if="customerId" id="customer-container" class="container">
      <CustomerDetails :customer_id="customerId" :set_customer_is_complete="setCustomerIsComplete" :edit_allowed="bookingStatus === 'pending'"/>
    </div>
    <div v-if="customerIsComplete" id="checkout-container">
      <BookingCheckout
        :booking_id="bookingId"
        :booking_status="bookingStatus"
        :booking_type="bookingType"
        :booking_price="bookingPrice"
        :booking_date="bookingDate"
        :set_booking_status="setBookingStatus"
        :set_booking_receipt_path="setBookingReceiptPath"
      />
    </div>
    <div v-if="bookingReceiptPath" id="receipt-container" class="container">
      <a id="receipt-button" :href="apiUrl + bookingReceiptPath" target="_blank">Visa kvitto</a>
    </div>
  </div>
</template>

<script>
  import CustomerDetails from "@/components/CustomerDetails"
  import BookingCheckout from "@/components/BookingCheckout";
  import Vue from 'vue'
  import axios from 'axios'
  import VueAxios from 'vue-axios'

  Vue.use(VueAxios, axios);

  let bookingNotFound = false;

  let bookingId = window.location.href.match(/booking\/([a-z0-9]+)/);
  if (bookingId !== null) bookingId = bookingId[1];

  let bookingStatus, bookingType, bookingPrice, bookingDate, bookingReceiptPath, bookingPassed = undefined;

  let customerId, customerIsComplete = undefined;

  export default {
    name: "BookingPage",
    components: {
      BookingCheckout,
      CustomerDetails
    },
    data() { return {
      bookingId: bookingId,
      bookingStatus: bookingStatus,
      bookingType: bookingType,
      bookingPrice: bookingPrice,
      bookingDate: bookingDate,
      bookingNotFound: bookingNotFound,
      bookingReceiptPath: bookingReceiptPath,
      bookingPassed: bookingPassed,
      customerId: customerId,
      customerIsComplete: customerIsComplete,
    }},
    methods: {
      setBookingStatus(status) {
        this.bookingStatus = status;
      },
      setCustomerIsComplete(isComplete) {
        this.customerIsComplete = isComplete;
      },
      setBookingReceiptPath(path) {
        this.bookingReceiptPath = path;
      }
    },
    mounted() {
      axios
        .get(this.apiUrl + 'booking/full/' + this.bookingId)
        .then(function(response) {
          if (!response.data || !response.data.success) {
            this.bookingNotFound = true;
            return;
          }

          this.bookingStatus = response.data.data.status;
          this.bookingType = response.data.data.type;
          this.bookingDate = response.data.data.photography_date;
          this.bookingPrice = response.data.data.price_photography;
          this.bookingReceiptPath = response.data.data.receipt_path;
          this.bookingPassed = new Date(this.bookingDate).getTime() < new Date().getTime();
          this.customerId = response.data.data.customer_id;
        }.bind(this));
    }
  }
</script>

<style scoped lang="stylus">

  div#booking-page
    padding-bottom: 50px

    h2
      font-size: 30px
      margin: 0 auto 30px

    h4
      margin: 40px 0 10px
      font-size: 14px
      text-align: center

    p
      max-width: initial

    ol
      display: table
      font-size: 14px
      line-height: 30px
      margin: 0 auto

    div#information-container
      background-color: #f5f5f5
      padding: 40px 0

    div#customer-container
      margin: 50px auto

    div#receipt-container
      margin: 30px auto

      a#receipt-button
        margin: 0px auto
        display: table
        background-color: #eee
        padding: 10px 20px
        font-size: 14px

        &:hover
          background-color: #e6e6e6

</style>