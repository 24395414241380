<template>
  <div id="tos">
    <h1 style="padding-top:10px;">Beställningsvillkor</h1>
    <h2>1. Allmänt</h2>
    <div>
      <h3>1.1.</h3>
      <p>
        För att lägga en bildbeställning krävs det att du är 18 år fyllda. Är du minderårig krävs tillstånd från målsman.
      </p>
    </div>


    <div>
      <h3>1.3.</h3>
      <p>
        Jag reserverar mig för ändringar av priser och prisfel i systemet och kan då justera ordern/orderbeloppet eller ge kunden möjligheten att kostnadsfritt häva beställningen.
      </p>
    </div>
    <div>
      <h3>1.4.</h3>
      <p>
        Jag förbehåller mig rätten att häva en beställning om bedrägeri misstänks. Försök till bedrägeri polisanmäls.
      </p>
    </div>
    <div>
      <h3>1.5.</h3>
      <p>
        Alla priser på hemsidan är inklusive moms på 25%.
      </p>
    </div>
    <h2>2. Betalning</h2>
    <div>
      <h3>2.1.</h3>
      <p>
        Som konsument betalar du priset för beställningen via Swish. Inga extra avgifter tillkommer.
      </p>
    </div>
    <div>
      <h3>2.2.</h3>
      <p>
        Betalningen initieras via denna bokningssida och slutförs genom Swish-appen.
      </p>
    </div>
    <h2>3. Leverans</h2>
    <div>
      <h3>3.1.</h3>
      <p>
        Det tar upp till 6 veckor innan färdiga digitala bilder levereras.
      </p>
    </div>
    <div>
      <h3>3.2.</h3>
      <p>
        I samband med högtider eller sjukdom förbehåller jag mig rätten att förlänga leveranstiden.
      </p>
    </div>
    <div>
      <h3>3.3.</h3>
      <p>
        Skulle du mot förmodan vara missnöjd med produkterna så har du rätt till en ersättande produkt/fotografering om jag anser att det förekommer brister i kvaliteten.
      </p>
    </div>
    <h2>4. Ändring / Avbeställning</h2>
    <div>
      <h3>4.1.</h3>
      <p>
        Från att ordern läggs har du som kund möjlighet att ändra i ordern kostnadsfritt inom 48 timmar.
      </p>
    </div>
    <div>
      <h3>4.2.</h3>
      <p>
        Från att ordern läggs har du som kund möjlighet att avbeställa ordern kostnadsfritt i 48 timmar eller tills din betalning inkommit (inom 48h).
      </p>
    </div>
    <h2>5. Ångerrätt</h2>
    <div>
      <h3>5.1.</h3>
      <p>
        Avbeställs inte ordern inom angiven tidsram hävs också ångerrätten. Distans- och hemförsäljningslagen gäller inte då köpet avser personliga produkter som inte kan återförsäljas.
      </p>
    </div>
    <h2>6. Force Majeure</h2>
    <div>
      <h3>6.1.</h3>
      <p>
        Händelse såsom krig, naturkatastrof, stridsåtgärder på arbetsmarknaden, myndighets beslut, uteblivna leveranser från underleverantörer, fördyrande omständigheter samt liknande händelser utanför min kontroll vilka ej skäligen kunnat förutses befriar mig från mina förpliktelser att fullgöra ingångna avtal.
      </p>
    </div>
    <h2>7. Hantering av personuppgifter</h2>
    <div>
      <h3>7.1.</h3>
      <p>
        Jag följer Personuppgiftslagen och ger aldrig bort information som registreras på sidan till tredje part. Informationen kan dock komma att användas i eget marknadsföringssyfte såsom e-postutskick.
      </p>
    </div>
    <h2>8. Övrigt</h2>
    <div>
      <h3>8.1.</h3>
      <p>
        Vid eventuell tvist följer jag Allmänna Reklamationsnämndens rekommendationer.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderTerms"
}
</script>

<style scoped lang="stylus">
  div#tos
    margin: 0 auto
    padding: 20px

    h1
      margin-top: 10px
      font-size: 24px

    h2
      margin-left: 5px
      margin-top: 30px
      font-size: 20px

    div
      display: flex
      justify-content: flex-start
      margin: 7px 0 7px 15px

      h3
        width: 35px
        margin: 0
        font-weight: normal
        letter-spacing: 1px
        line-height: 18px
        font-size: 14px
        text-align: left
        flex-shrink: 0

      p
        line-height: 18px
        font-size: 12px
        text-align: left
        margin: 0

</style>