<template>
  <div id="customer-details">
    <h2>Dina uppgifter</h2>
    <div id="customer-form" v-if="editEnabled && edit_allowed">
      <input type="email" v-model="email" placeholder="E-post" required/>
      <input type="text" v-model="phone" placeholder="Telefon" required/>
      <input type="text" v-model="name" placeholder="För- & efternamn" required/>
      <input type="text" v-model="address" placeholder="Adress" required/>
      <input type="text" v-model="zipCode" placeholder="Postnummer" required/>
      <input type="text" v-model="city" placeholder="Postort" required/>
      <input v-if="countryEnabled" type="text" v-model="country" placeholder="Land" required/>
      <input v-if="type === 'business'" type="text" v-model="companyName" placeholder="Företagsnamn" required/>
      <input v-if="type === 'business'" type="text" v-model="organisationNumber" placeholder="Organisationsnummer" required/>
      <input v-if="type === 'business'" type="text" v-model="vatNumber" placeholder="Momsregisternummer" required/>
      <input type="submit" value="Spara" v-on:click="saveDetails"/>
      <span class="error-message" v-html="errorMessage"></span>
    </div>
    <div id="customer-summary" v-if="!editEnabled || !edit_allowed">
      <p>{{email}}</p>
      <p>{{phone}}</p>
      <p>{{name}}</p>
      <p>{{address}}, {{zipCode}} {{city}}{{countryEnabled ? ', '+country : ''}}</p>
      <p v-if="type === 'business'">{{companyName}} {{organisationNumber}}, VAT: {{vatNumber}}</p>
      <a id="edit-button" href="javascript:void(0)" v-if="edit_allowed" v-on:click="editEnabled = true">Ändra uppgifter</a>
    </div>
  </div>
</template>

<script>
import axios from "axios";

let editEnabled = undefined;
let companyEnabled = false;
let countryEnabled = false;

let errorMessage = undefined;

let name, email, phone, address, zipCode, city, country, type, companyName, organisationNumber, vatNumber = undefined;

export default {
  name: "CustomerDetails",
  props: [
      "customer_id",
      "set_customer_is_complete",
      "edit_allowed"
  ],
  data() { return {
    editEnabled: editEnabled,
    companyEnabled: companyEnabled,
    countryEnabled: countryEnabled,
    errorMessage: errorMessage,
    name: name,
    email: email,
    phone: phone,
    address: address,
    zipCode: zipCode,
    city: city,
    country: country,
    type: type,
    companyName: companyName,
    organisationNumber: organisationNumber,
    vatNumber: vatNumber,
  }},
  methods: {
    saveDetails() {
      let formData = new FormData();
      formData.append('name', this.name || '');
      formData.append('email', this.email || '');
      formData.append('phone_number', this.phone || '');
      formData.append('address', this.address || '');
      formData.append('zipcode', this.zipCode || '');
      formData.append('city', this.city || '');
      formData.append('country', this.country || '');
      formData.append('type', this.type || '');
      if (this.type === 'business') {
        formData.append('company_name', this.companyName);
        formData.append('organisation_number', this.organisationNumber);
        formData.append('vat_number', this.vatNumber);
      }

      axios
        .post(this.apiUrl + 'customer/update/' + this.customer_id, formData)
        .then(function(response) {
          if (!response.data || !response.data.success) {
            this.errorMessage = response.data.data.error || 'Okänt fel inträffade, vänligen försök igen.';
            return;
          }

          this.errorMessage = '';

          this.editEnabled = !this.isComplete();
          if (this.set_customer_is_complete)
            this.set_customer_is_complete(this.isComplete());
        }.bind(this));
    },
    isComplete() {
      return this.name && this.email && this.phone && this.address && this.zipCode && this.city && this.country &&
        (this.type === 'individual' || (this.companyName && this.organisationNumber && this.vatNumber));
    }
  },
  mounted() {
    axios
      .get(this.apiUrl + 'customer/full/' + this.customer_id)
      .then(function(response) {

        this.name = response.data.data.name;
        this.email = response.data.data.email;
        this.phone = response.data.data.phone_number;
        this.address = response.data.data.address;
        this.zipCode = response.data.data.zipcode;
        this.city = response.data.data.city;
        this.country = this.countryEnabled ? response.data.data.country : 'Sverige';
        this.type = this.companyEnabled ? response.data.data.type : 'individual';
        this.companyName = response.data.data.company_name;
        this.organisationNumber = response.data.data.organisation_number;
        this.vatNumber = response.data.data.vat_number;

        this.editEnabled = !this.isComplete();

        if (this.set_customer_is_complete)
          this.set_customer_is_complete(this.isComplete());
      }.bind(this));
  }
}
</script>

<style scoped lang="stylus">
  div#customer-details

    div#customer-form
      display: flex
      flex-direction: column
      align-items: center

      input
        padding: 5px 10px
        outline: none
        border: 1px solid #ddd
        box-sizing: border-box
        width: 95%
        max-width: 500px

        & + input
          margin-top: 10px

        &[type=submit]:hover
          background-color: #e5e5e5

      span.error-message
        margin-top: 10px
        font-size: 12px
        color: #c00

    div#customer-summary

      a#edit-button
        display: table
        margin: 10px auto
        font-size: 12px
        background-color: #eee
        padding: 5px 10px

        &:hover
          background-color: #e6e6e6

</style>