<template>
  <div id="order-page">
      <div id="information-container">
      <div v-if="orderNotFound" id="order-not-found">
        <p>Felaktig urvalslänk, vänligen kontakta mig för att få en ny länk.</p>
      </div>
      <div v-if="orderStatus === 'finalized'" class="container">
        <h2>Din bildbeställning</h2>
        <p>Din beställning har nu färdigställts och levererats. Jag hoppas att du är nöjd med resultatet!</p>
      </div>
      <div v-if="orderStatus === 'paid'" class="container">
        <h2>Din bildbeställning</h2>
        <p>Här nedan kan du se de bilder du valt att beställa samt följa status på din beställning.</p>
      </div>
        <div v-if="orderStatus === 'ordered' || orderStatus === 'paying'" class="container">
          <h2>Din bildbeställning</h2>
          <p>Du har nu beställt bilderna nedan, men innan beställningen kan påbörjas måste den först betalas.</p>
          <h4>Så här betalar du med Swish</h4>
          <ol>
            <li>Fyll i det nummer du vill betala med och tryck på Skicka förfrågan-knappen</li>
            <li>Öppna Swish-appen och bekräfta betalningen</li>
            <li>Beställningen är nu färdigbetald och du får nu en uppskattat datum som de färdiga bilderna senast beräknas levereras</li>
          </ol>
        </div>
      <div v-if="orderStatus === 'pending' || orderStatus === 'ordering'" class="container">
        <h2>Ditt bildgalleri</h2>
        <p>Här nedan visas ert bildurval från vår fotografering. Bilderna är endast lätt redigerade och motsvarar inte det färdiga resultatet.</p>
        <h4>Så här beställer du dina bilder</h4>
        <ol>
          <li>Gå igenom bilderna och hitta dina favoriter, du kan förstora varje bild genom att klicka på den</li>
          <li>"Hjärta" de bilder du önskar att beställa, du kan även lämna en kort kommentar till varje bild om det är något du önskar få retuscherat</li>
          <li>Priset för beställningen räknas automatiskt ut efter mina bildpaket och visas längst ner</li>
          <li>När du är nöjd med dina val så kontrollerar du att dina uppgifter nedan stämmer, godkänn därefter beställningsvillkoren och klicka på Beställ-knappen längst ner</li>
          <li>Efter att du lagt beställningen är det dags att betala. Betalning sker via Swish</li>
          <li>Fyll i det nummer du vill betala med och tryck på Skicka förfrågan-knappen</li>
          <li>Öppna Swish-appen och bekräfta betalningen</li>
          <li>Beställningen är nu genomförd och du får nu en uppskattad vecka som de färdiga bilderna senast beräknas levereras</li>
        </ol>
      </div>
    </div>
    <div v-if="images.length" id="gallery-container" class="container">
      <Gallery v-if="orderStatus !== 'finalized'" :images="images" :selection="selection" :order_status="orderStatus"></Gallery>
    </div>
    <div v-if="customerId && orderStatus === 'pending'" id="customer-container" class="container">
      <CustomerDetails :customer_id="customerId" :edit_allowed="true"/>
    </div>
    <div v-if="!orderNotFound" id="checkout-container">
      <OrderCheckout
        :selection="selection"
        :order_id="orderId"
        :order_status="orderStatus"
        :set_order_status="setOrderStatus"
        :order_latest_delivery_date="orderLatestDeliveryDate"
        :order_minimum_value="orderMinimumValue"
        :order_discount="orderDiscount"
        :order_included="orderIncluded"
        :order_custom_prices="orderCustomPrices"
        :order_digital_prices="orderDigitalPrices"
        :order_images_in_selection="images.length"
        :set_order_latest_delivery_date="setOrderLatestDeliveryDate"
        :set_order_receipt_path="setOrderReceiptPath"
      ></OrderCheckout>
    </div>
    <div v-if="orderReceiptPath" id="receipt-container" class="container">
      <a id="receipt-button" :href="apiUrl + orderReceiptPath" target="_blank">Visa kvitto</a>
    </div>
  </div>
</template>

<script>
  import Gallery from "@/components/Gallery"
  import OrderCheckout from "@/components/OrderCheckout"
  import CustomerDetails from "@/components/CustomerDetails"
  import Vue from 'vue'
  import axios from 'axios'
  import VueAxios from 'vue-axios'

  Vue.use(VueAxios, axios);

  let images = [];
  let selection = [];

  let orderNotFound = false;

  let orderId = window.location.href.match(/order\/([a-z0-9]+)/);
  if (orderId !== null) orderId = orderId[1];

  let orderStatus, orderLatestDeliveryDate, orderReceiptPath, orderMinimumValue, orderDiscount, orderIncluded, orderCustomPrices, orderDigitalPrices = undefined;

  let customerId = undefined;

  export default {
    name: "OrderPage",
    components: {
      Gallery,
      OrderCheckout,
      CustomerDetails
    },
    data() { return {
      orderId: orderId,
      orderStatus: orderStatus,
      orderLatestDeliveryDate: orderLatestDeliveryDate,
      orderReceiptPath: orderReceiptPath,
      images: images,
      selection: selection,
      customerId: customerId,
      orderNotFound: orderNotFound,
      orderMinimumValue: orderMinimumValue,
      orderDiscount: orderDiscount,
      orderIncluded: orderIncluded,
      orderCustomPrices: orderCustomPrices,
      orderDigitalPrices: orderDigitalPrices
    }},
    methods: {
      setOrderStatus(status) {
        this.orderStatus = status;
      },
      setOrderLatestDeliveryDate(date) {
        this.orderLatestDeliveryDate = date;
      },
      setOrderReceiptPath(path) {
        this.orderReceiptPath = path;
      }
    },
    mounted() {
      axios
        .get(this.apiUrl + 'order/full/' + this.orderId)
        .then(function(response) {
          if (!response.data || !response.data.success) {
            this.orderNotFound = true;
            return;
          }

          this.selection = response.data.data.ordered_images;
          this.orderLatestDeliveryDate = response.data.data.latest_delivery_date;
          this.orderReceiptPath = response.data.data.receipt_path;
          this.orderStatus = response.data.data.status;
          this.orderMinimumValue = parseFloat(response.data.data.minimum_order_amount);
          this.orderDiscount = parseFloat(response.data.data.discount);
          this.orderIncluded = response.data.data.included;
          this.orderCustomPrices = response.data.data.custom_prices;
          this.orderDigitalPrices = response.data.data.digital_prices;

          axios
            .get(this.apiUrl + 'booking/full/' + response.data.data.booking_id)
            .then(function(response) {
              if (!response.data || !response.data.success) {
                this.orderNotFound = true;
                this.orderStatus = undefined;
                return;
              }

              this.customerId = response.data.data.customer_id;

              this.images = response.data.data.images;
            }.bind(this))
        }.bind(this));
    }
  }
</script>

<style scoped lang="stylus">

  div#order-page
    padding-bottom: 50px

    h2
      font-size: 30px
      margin: 0 auto 30px

    h4
      margin: 40px 0 10px
      font-size: 14px
      text-align: center

    p
      max-width: initial

    ol
      display: table
      font-size: 14px
      line-height: 30px
      margin: 0 auto

    div#information-container
      background-color: #f5f5f5
      padding: 40px 0

    div#gallery-container, div#customer-container
      margin: 50px auto

    div#receipt-container
      margin: 30px auto

      a#receipt-button
        margin: 0px auto
        display: table
        background-color: #eee
        padding: 10px 20px
        font-size: 14px

        &:hover
          background-color: #e6e6e6

</style>